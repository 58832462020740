.c-workshop {
    padding: 10rem 0;
    text-align: left;
    
    &__inner {
        max-width: 75%;
        margin: 0 22.5%;
        
        @media screen and (max-width: 767px){
            max-width: 85%;
        }
    }
    
    &__headline {
        font-size: 2.25rem;
        color: $color--primary;
        
        @media screen and (max-width: 767px){
            font-size: 1.5rem;
        }
    }
    
    &__list {
        margin-top: 4rem;
        text-align: left;
        
        &-item {
            background: #f7f5ef;
            padding: 3rem;
            display: flex;
            flex: 1 1;
            margin-bottom: 1rem;
            
            @media screen and (max-width: 767px){
                flex: none;
                display: block;
                text-align: center;
                padding: 1.75rem;
            }
        }
    }
    
    &__date {
        text-align: left;
        display: inline-block;
        display: block;
        width: 50%;
        font-size: 1.75rem;
        align-self: center;
        
        @media screen and (max-width: 767px){
            width: 100%;
            text-align: center;
            margin-bottom: 1.5rem;
            
        }
    }
    
    &__button {
        display: block;
        width: 50%;
        text-align: right;
        
        @media screen and (max-width: 767px){
            width: 100%;
            text-align: center;
        }
        
        a {
            display: inline-block;
            padding: 1rem 3rem;
            background: $color--primary;
            color: white;
            text-decoration: none;
            font-size: 1.125rem;
            font-family: "Muli", sans-serif;
            transition: .35s ease background;
            border-radius: 3px;
            
            &:hover {
                background: darken($color--primary,10%);
                transition: .35s ease background;
            }
        }
    }
}