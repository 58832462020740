.c-gallery {
    margin-left: 15%;
    margin-right: 15%;
    max-width: 100%;

    @media screen and (max-width: 1440px) {
       margin: auto;
    }

    &__grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-around;

        &-cell {
            flex-grow: 1;
            margin: 0.3rem;
            width: 40%;

            &.is--tree {
                width: 25%;
            }
        }

        &-cell img {
            display: block;

            &.responsive-image {
                max-width: 100%;
            }
        }
    }
}
