.o-content {
    flex: 0 0 auto;
    padding: $md-padding--default;

    &--margin {
        margin-bottom: $md-margin--default;
    }

    &--match {
        align-items: stretch;
        flex: 1 auto;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: calc(100% - #{$md-padding--default});
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
