.c-menu {
    position: absolute;
    right: 3rem;
    top: 6rem;
    z-index: 999;
    
    @media screen and (max-width: 1200px){
        display: none;
    }

    &__list {
        text-align: right;

        &-item {
            display: inline-block;
            margin-left: 2rem;

            @media screen and (max-width: 1340px){
                margin-left: 1rem;
            }
            
            &.is--button {
                padding: 1rem 2rem;
                background: $color--primary;
                transition: .35s ease background;
                border-radius: 3px;

                @media screen and (max-width: 1340px){
                    padding: 1rem 1rem;
                }
                    
                &:hover {
                    background: darken($color--primary,10%);
                    transition: .35s ease background;
                }
                
                a {
                    color: white !important;
                }
            }    

            a {
                font-family: "Rubik", sans-serif;
                font-size: 1.125rem;
                text-decoration: none;
                color: white;
                transition: .35s ease color;
                font-weight: 400;
                
                &.is--active {
                    color: $color--primary !important;
                    border-bottom: 3px solid $color--primary;
                    padding-bottom: 1rem;

                    &:hover {

                    }
                }
                
                .is--grey & {
                    color: #aaa;
                }

                .is--black & {
                    color: #000000;
                }
                
                &:hover {
                    color: $color--primary;
                    transition: .35s ease color;
                }
            }
            
            &.is--button {
                a {
                    &.is--active {
                        color: white !important;
                        border-bottom: 0 !important;
                    }
                }
            }
        }
    }
}

.c-nav {
    @media screen and (min-width: 1201px){
        display: none;
    }
    
    display: block;
    
    &__toggle {
        position: fixed;
        top: 5rem;
        right: 0rem;
        height: 6rem;
        width: 7rem;
        z-index: 10000;
        background: none;
        border: 0;
        outline: 0;
        background: white;
        box-shadow: 0 8px 20px rgba(0,0,0,0.15);
        
        @media screen and (max-width: 767px){
            height: 4rem;
            width: 5rem;
            top: 2.75rem;
        }
        
        &-line {
            height: .5rem;
            width: 50%;
            left: 50%;
            transform: translate(-50%,0);
            position: absolute;
            background: grey;
            
            @media screen and (max-width: 767px){
                height: .35rem;
            }
            
            &:first-child {
                top: 25%;
            }
            
            &:nth-child(2){
                top: 50%;
                margin-top: -.25rem;
                
                @media screen and (max-width: 767px){
                    margin-top: -.175rem;
                }
            }
            
            &:last-child {
                bottom: 25%;
            }
        }
    }
    
    &__menu {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 5000;
        background: $color--primary;
        text-align: center;
        display: none;
        
        &.is--active {
            display: block;
        }
    }
    
    &__list {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%,-50%);
        
        &-item {
            a {
                color: white;
                text-decoration: none;
                font-size: 4vw;
                line-height: 2;
                
                @media screen and (max-width: 767px){
                    font-size: 8vw;
                }
            }
        }
    }
}
