@media print {
    *,
    p,
    div,
    blockquote,
    li,
    p,
    div,
    blockquote,
    li {
        &::after,
        &::before,
        &::first-letter,
        &::first-line {
            box-shadow: none !important;
            text-shadow: none !important;
        }
    }

    a {
        text-decoration: underline;

        &:visited {
            text-decoration: underline;
        }
    }

    abbr[title] {
        ::after {
            content: '("attr(title)")';
        }
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: .0625rem solid $color--black;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    table {
        border-collapse: collapse;

        td,
        th {
            background-color: $color--white;
        }
    }

    /* Custom */
    .c-nav,
    .c-footer {
        display: none;
    }
}
