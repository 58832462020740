.c-footer {
    width: 100%;

    &__main {
        align-items: center;
        display: flex;
        flex: 1 2;
        padding: 5rem 2rem 2rem 2rem;
        justify-content: center;

        @media screen and (max-width: 1024px){
            display: block;
            flex: none;
        }

        .c-contact {
            margin-right: 10rem;

            @media screen and (max-width: 1800px){
                margin-right: 7rem;
            }

            @media screen and (max-width: 1440px){
                margin-right: 4rem !important;
            }

            @media screen and (max-width: 767px){
                margin-bottom: 3rem;
                margin-right: 0;
            }

            &__headline {
                color: $color--primary;
                font-size: 2rem;
                margin-bottom: 1rem;
            }

            &__text {
                font-size: 1.375rem;
                line-height: 1.75;
            }
        }

        .c-partner {
            // @media screen and (max-width: 1800px){
            //     margin-top: 3rem;
            // }
            @media screen and (max-width: 1440px){
                margin-right: 0 !important;
            }

            &__headline {
                color: $color--primary;
                font-size: 2rem;
                margin-bottom: 2rem;
            }

            &__list {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                &-item {
                    display: inline-block;
                    margin-right: 3rem;
                    opacity: .35;
                    transition: 1s ease opacity;

                    @media screen and (max-width: 1024px){
                        margin: 1rem;
                        text-align: center;

                        img {
                            width: 125px;
                        }
                    }

                    &:hover {
                        opacity: 1;
                        transition: 1s ease opacity;
                    }
                }
            }
        }
    }

    &__mini {
        align-items: center;
        display: flex;
        flex: 1 1;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 2rem;
        width: 100%;

        @media screen and (max-width: 767px){
            display: block;
            flex: none;
        }

        > div {
            margin-top: 2rem;

            @media screen and (max-width: 767px){
                width: 100%;
            }
        }

        .c-footmenu {
            text-align: right;

            @media screen and (max-width: 767px){
                text-align: left;
            }

            &__list {
                &-item {
                    display: inline-block;
                    margin-left: 1rem;

                    @media screen and (max-width: 767px){
                        margin-left: 0;
                        margin-right: .5rem;
                    }

                    a {
                        color: $color--primary;
                        text-decoration: none;

                        @media screen and (max-width: 767px){
                            display: inline-block;
                            padding: .25rem;
                        }
                    }
                }
            }
        }
    }
}
