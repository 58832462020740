
// ====================================================================================
// Grid : Row
// ====================================================================================

.o-row {
    margin: 0 auto;
    max-width: $fg-width;

    /** Full width **/
    &__stretch {
        margin: 0;
        max-width: 100%;
    }

    /** Remove inner padding **/
    &__collapse > [class^='o-col__'] {
        padding-left: 0;
        padding-right: 0;
    }

    /** Nested **/
    .o-row {
        margin-left: -$fg-gutter;
        margin-right: -$fg-gutter;
        max-width: none;
        width: auto;
    }
}
