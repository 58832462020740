.c-initiative {
    background: #f7f5ef;
    padding: 10rem 0;
    position: relative;
    overflow: hidden;

    &.is--yellow {
        padding: 15rem 0;

        background: $color--primary;

        .c-initiative__headline,
        .c-initiative__text,
        .c-initiative__list-item {
            color: white;
        }

        @media screen and (max-width: 767px){
            padding: 5rem 0;
        }
    }

    &.is--nobg {
        background: transparent;
    }

    @media screen and (max-width: 767px){
        padding: 4rem 0;
    }

    &.is--white {
        background: white;
    }

    &__video {
        position: absolute;
        padding-top: 30px;
        height: 0;
        width: 75%;
        padding-bottom: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
        top: 50%;

        @media screen and (max-width: 1440px){
            position: relative;
            transform: none;
            padding-bottom: 56.25%;
            width: 100%;
            left: 0;
            top: 0;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0 40px 100px rgba(0,0,0,.15);
        }
    }

    &__inner {
        max-width: 75%;
        margin: 0 auto;
        display: flex;
        flex: 2;

        @media screen and (max-width: 1440px){
            display: block;
            flex: none;
        }

        @media screen and (max-width: 767px){
            max-width: 85%;
        }
    }

    &__headline {
        color: $color--primary;
        font-size: 1.75rem;
        margin-bottom: 2rem;
    }

    &__text {
        font-size: 1.375rem;
        line-height: 1.65;
        margin-bottom: 2rem;

        @media screen and (max-width: 767px){
            font-size: 1rem;
        }
    }

    &__left {
        width: 50%;

        @media screen and (max-width: 1440px){
            width: 100%;
        }
    }

    &__collage {
        position: absolute;
        right: 0%;
        left: 52%;
        top: 0;
        bottom: 0;
        text-align: center;

        @media screen and (max-width: 1440px){
            width: 100%;
            position: static;
            margin-top: 5rem;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 70%;
            transform: translate(-50%,-50%);
            box-shadow: 0 40px 100px rgba(0,0,0,.25);

            @media screen and (max-width: 1440px){
                width: 100%;
                position: static;
                transform: none;
            }
        }
    }

    &__button {
        display: inline-block;
        background: $color--primary;
        color: white;
        padding: 1rem 3rem;
        text-decoration: none;
        transition: .35s ease background;
        border-radius: 3px;
        font-size: 1.125rem;
        font-family: "Muli";

        &:hover {
            background: darken($color--primary,10%);
            transition: .35s ease background;
        }
    }

    &__list {
        font-size: 1.375rem;
        margin-bottom: 2rem;

        @media screen and (max-width: 767px){
            font-size: 1rem;
        }

        &-item {
            line-height: 1.65;

            &:before {
                content: "";
                display: inline-block;
                height: .5rem;
                width: .5rem;
                border-radius: 100%;
                vertical-align: 2px;
                margin-right: 1.25rem;
                background: $color--primary;

                .is--yellow & {
                    background: white;
                }
            }
        }
    }
}
