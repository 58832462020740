/* ------------ VIDEO MODUL ------------ */

.c-video {
    margin: 2rem auto;
    width: 75%;
  
    &__wrapper {
      border-radius: .375rem;
      height: 0;
      position: relative;
      width: 100%;
  
  
      &-43 {
        padding-top: 75%;
      }
  
      &-169 {
        padding-top: 56%;
      }
    }
  
    &__iframe {
      background-color: transparent;
      border-radius: .3125rem;
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
  