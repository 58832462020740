.c-logo {
    position: fixed;
    left: 0;
    top: 2.5rem;
    padding: 1rem;
    background: white;
    box-shadow: 0 8px 20px rgba(0,0,0,0.15);
    z-index: 10000;
    
    @media screen and (max-width: 1024px){
        position: fixed;
    }
    
    @media screen and (max-width: 767px){
        padding: .5rem;
    }
    
    img {
        @media screen and (max-width: 767px){
            width: 50px;
        }
    }
}
