.c-teaser {
    padding: 10rem 0;
    
    @media screen and (max-width: 767px){
        padding: 2rem 0;
    }
    
    &.is--two {
        padding: 5rem 0 0 0;
        
        &__inner {
            flex: 1 1;
        }
        
        .c-teaserbox {
            width: 50%;
            
            @media screen and (max-width: 1440px){
                width: 100%;
            }
        }
    }

    &__inner {
        max-width: 75%;
        margin: 0 auto;
        display: flex;
        flex: 1 1 1;
        
        @media screen and (max-width: 1440px){
            flex: none;
            display: block;
            max-width: 85%;
        }
    }

    .c-teaserbox {
        background: #f7f5ef;
        padding: 3.75rem;
        width: 33%;
        
        @media screen and (max-width: 1440px){
            width: 100%;
        }
        
        @media screen and (max-width: 767px){
            padding: 1.75rem;
        }

        &:nth-child(2){
            margin: 0 2rem;
            
            @media screen and (max-width: 1440px){
                margin: 2rem 0;
            }
        }

        &__headline {
            color: $color--primary;
            font-size: 2rem;
            margin-bottom: 2rem;
            line-height: 1.25;
            
            @media screen and (max-width: 767px){
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }
        }

        &__text {
            font-size: 1.375rem;
            line-height: 1.65;
            margin-bottom: 2rem;
            
            @media screen and (max-width: 767px){
                font-size: 1rem;
            }
        }

        &__link {
            display: inline-block;
            background: $color--primary;
            color: white;
            padding: 1rem 3rem;
            text-decoration: none;
            transition: .35s ease background;
            border-radius: 3px;
            font-size: 1.125rem;
            font-family: "Muli";
            
            @media screen and (max-width: 767px){
                padding: 1.25rem;
            }
            
            &:hover {
                background: darken($color--primary,10%);
                transition: .35s ease background;
            }
        }
    }
}
