.e-form {
    width: 100%;

    /** form wrapper(grid) **/
    &__group {
        display: flex;
        flex-direction: row;
        margin: 0 (-$fg-gutter) ($fg-gutter * 2);
    }

    &__wrap {
        clear: both;
        flex: 0 1 auto;
        padding-left: $fg-gutter;
        padding-right: $fg-gutter;
        position: relative;
        width: 100%;

        &.is--half {
            width: 50%;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }

    /** form fieldset and legend **/
    &__fieldset {
        margin-bottom: 2.5rem;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__legend {
        border-bottom: .0625rem solid $border-form-secondary;
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 1.875rem;
        padding-bottom: .9375rem;
    }

    /** form labels **/
    &__label {
        display: block;
        margin-bottom: .3125rem;

        /** radio & checkbox **/
        &.is--checkbox,
        &.is--radio {
            cursor: pointer;
            padding: .25rem 0 0 2.5rem;
            position: relative;

            &:before {
                background-color: $color--white;
                border: .0625rem solid $border-form-primary;
                content: '';
                display: inline-block;
                height: 1.875rem;
                left: 0;
                position: absolute;
                top: 0;
                transition: $ts--fast;
                width: 1.875rem;
                z-index: 1;
            }

            &:hover {
                &:before {
                    background-color: $bg-form-focus;
                    box-shadow: 0 .0625rem .125rem $color-trans-1;
                }
            }
        }

        &.is--radio {
            &:before {
                border-radius: 50%;
            }
        }

        &.is--checkbox {
            &:after {
                background: transparent;
                border: .1875rem solid $color--white;
                border-right: 0;
                border-top: 0;
                content: '';
                display: inline-block;
                height: .625rem;
                left: .3125rem;
                opacity: 0;
                position: absolute;
                top: .375rem;
                transform: rotate(-45deg);
                transition: $ts--fast;
                width: 1.25rem;
                z-index: 2;
            }
        }
    }

    /** form fields **/
    &__field {
        background-color: $bg-form-primary;
        border: .0625rem solid $border-form-primary;
        box-shadow: none;
        height: 2.875rem;
        outline: none;
        padding: 0 .9375rem;
        transition: $ts--fast;
        width: 100%;

        &:hover {
            box-shadow: 0 .0625rem .125rem $color-trans-1;
        }

        &:focus {
            background-color: $bg-form-focus;
            box-shadow: 0 .0625rem .125rem $color-trans-1;
            outline: none;

            &:valid {
                border-color: $color-form-success;
            }

            &:invalid {
                border-color: $color-form-error;
            }
        }

        /** radio & checkbox **/
        &-radio,
        &-checkbox {
            left: 0;
            position: absolute;
            top: 0;
            visibility: hidden;

            &:checked + .is--checkbox {
                &:before {
                    background-color: $color-form-action;
                    border-color: $color--white;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:checked + .is--radio {
                &:before {
                    background-color: $color-form-action;
                    border-color: darken($color-form-action, 15%);
                    border-width: .1875rem;
                }
            }
        }

        /** searchfield **/
        &.is--search {
            float: left;
            width: calc(100% - 2.875rem);
        }

        /** textarea **/
        &.is--area {
            height: 12.5rem;
            padding-bottom: .9375rem;
            padding-top: .9375rem;
        }
    }
}
