.is--nostage {
    margin-top: 13rem;

    @media screen and (max-width: 767px) {
        margin-top: 8rem;
    }
}

.c-stage {
    background: #52625f;
    height: 100vh;
    position: relative;
    width: 100%;

    &__text {
        left: 50%;
        position: absolute;
        text-align: center;
        top: 66.6666%;
        transform: translate(-50%, -50%);
        width: 75%;

        &-headline {
            color: white;
            font-size: 3.25rem;
            margin-bottom: 1rem;
            text-shadow: 0 7px 8px rgba(0, 0, 0, 1);
            @media screen and (max-width: 767px) {
                font-size: 1.75rem;
            }

            &.is--center {
                color: white;
                font-size: 3.25rem;
                text-align: center;
                text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
                @media screen and (max-width: 767px) {
                    font-size: 1.75rem;
                }
            }
        }

        &-subline {
            color: white;
            margin-bottom: 2rem;
            text-shadow: 0 3px 8px rgba(0, 0, 0, 0.35);
        }

        &-button {
            background: $color--primary;
            border-radius: 3px;
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
            color: white;
            display: inline-block;
            font-family: "Muli";
            font-size: 1.125rem;
            padding: 1rem 3rem;
            text-decoration: none;
            transition: 0.35s ease background;

            &:hover {
                background: darken($color--primary, 10%);
                transition: 0.35s ease background;
            }
        }
    }
}
