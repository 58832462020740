html {
    // sass-lint:disable-all
    -ms-overflow-style: scrollbar;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: $color-tap;
    -webkit-text-size-adjust: 100%;
    // sass-lint:enable-all

    box-sizing: border-box;
}

* {
    box-sizing: inherit;

    &::before,
    &::after {
        box-sizing: inherit;
    }

    &::-moz-selection {
        background: $color--secondary;
        color: $color--white;
    }

    &::selection {
        background: $color--secondary;
        color: $color--white;
    }
}

:root {
    tab-size: 4;
}

html,
body {
    font-size: 100%;
    height: 100%;
    width: 100%;
}

body {
    background-color: $bg--default;
    color: $color--secondary;
    font-family: $ff--default;
    font-size: $fs--default;
    font-style: normal;
    font-weight: $fw--default;
    line-height: $fh--default;
    margin: 0;
    text-rendering: optimizeSpeed;
    // sass-lint:disable-all
    @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
    // sass-lint:enable-all
}

@at-root {
    @-ms-viewport {
        width: device-width;
    }
}

.no-transition *,
.no-transition *:before,
.no-transition *:after {
    transition: none !important;
}

[tabindex='-1'] {
    &:focus {
        outline: 0 !important;
    }
}

//iOS "clickable elements" fix for role="button"
[role='button'] {
    cursor: pointer;
}

//Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
a,
area,
button,
[role='button'],
label,
select,
summary,
textarea {
    touch-action: manipulation;
}

input {
    &:not([type='range']) {
        touch-action: manipulation;
    }
}

//Add the correct display in Edge, IE, IE 9 and Firefox.
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

//Add the correct display in IE 9-.
audio,
canvas,
progress,
video {
    display: inline-block;
}

//Add the correct display in iOS 4-7.
audio {
    &:not([controls]) {
        display: none;
        height: 0;
    }
}

//Add the correct vertical alignment in Chrome, Firefox, and Opera.
progress {
    vertical-align: baseline;
}

figure {
    margin: 0;
}

video {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

p,
dd,
dl,
ul,
ol,
ul ul,
ol ul {
    color: $color--secondary;
    font-size: $ff--default;
    margin: 0;
    padding: 0;
    text-rendering: optimizeSpeed;
}

dfn,
i,
em {
    font-style: italic;
}

del {
    text-decoration: line-through;
}

li {
    list-style: none;
    padding: 0;
}

b,
strong {
    font-weight: 700;
}

//Remove the gray background on active links in IE 10 and gaps in links underline in iOS 8+ and Safari 8+
a {
    -webkit-text-decoration-skip: objects; //sass-lint:disable-line no-vendor-prefixes
    background-color: transparent;
    outline: 0;

    &:active,
    &:hover {
        outline-width: 0;
    }
}

//Remove the margin in Firefox and Safari.
button,
input,
optgroup,
select,
textarea {
    border: 0;
    font: inherit;
    line-height: inherit;
    margin: 0;
    text-rendering: optimizeSpeed;
}

//Show the overflow in IE and Edge
button,
input {
    overflow: visible;
}

//Prevent a WebKit bug where destroys native `audio` and `video` controls in Android 4.
//Correct the inability to style clickable types in iOS and Safari.
button,
html [type='button'],
[type='reset'],
[type='submit'],
[type='email'],
[type='password'],
[type='number'] {
    -webkit-appearance: button; //sass-lint:disable-line no-vendor-prefixes
}

//Remove the inner border and padding in Firefox.
button,
[type='button'],
[type='reset'],
[type='submit'] {
    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }
}

[type='button'],
[type='reset'],
[type='submit'] {
    &:focus,
    &:-moz-focusring {
        outline: 0 none !important;
    }
}

//Remove the default appearance of temporal inputs to avoid a Mobile Safari
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    -webkit-appearance: listbox; //sass-lint:disable-line no-vendor-prefixes
}

//Correct the odd appearance in Chrome and Safari and correct the outline style in Safari.
[type='search'] {
    -webkit-appearance: textfield; //sass-lint:disable-line no-vendor-prefixes
    outline-offset: -.125rem;
}

[type='search'],
[type='search'] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button {
        -webkit-appearance: none; //sass-lint:disable-line no-vendor-prefixes
    }
}

[type='number'],
[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none; //sass-lint:disable-line no-vendor-prefixes
        height: auto;
        margin: 0;
    }
}

[type='number'] {
    -moz-appearance: textfield; //sass-lint:disable-line no-vendor-prefixes
}

//Correct the inability to style clickable types in iOS and Safari.
::-webkit-file-upload-button {
    -webkit-appearance: button; //sass-lint:disable-line no-vendor-prefixes
    font: inherit;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
    color: $color--secondary;
    opacity: 1;
}

select,
option {
    font-family: arial, helvetica, sans-serif;
}

button,
select {
    text-transform: none;
}

select {
    width: 100%;
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

// Reset the entire legend element to match the `fieldset`
legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    font-size: $fs--default;
    line-height: inherit;
    max-width: 100%;
    padding: 0;
    white-space: normal;
    width: 100%;
}

textarea {
    font-size: $fs--default;
    overflow: auto;
    resize: vertical;
}

pre {
    background-color: $color--secondary;
    color: $color--white;
    display: inline-block;
    margin: 0;
    padding: .3125rem .625rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

summary {
    display: list-item;
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    display: inline-block;
    height: auto;
    max-width: 100%;
    user-select: none;
    vertical-align: middle;
}

svg {
    &:not(:root) {
        overflow: hidden;
    }
}
