
// ==========================================================================
// Mixin :: WebFonts (@include font-face('roboto', $path-webfonts, 'roboto-light-webfont', 300, 'Roboto Light');)
// ==========================================================================

@mixin font-face($font-name, $fonts-path, $file-name, $weight: normal, $font-local: '', $style: normal) {
    @font-face {
        font-family: $font-name;
        font-style: $style;
        font-weight: $weight;
        src: local($font-local),
        local($font-local),
        url($path-webfonts + $file-name + '.woff2') format('woff2'),
        url($path-webfonts + $file-name + '.woff') format('woff');
    }
}

// ==========================================================================
// Webfonts :: Roboto
// ==========================================================================

@include font-face('roboto', $path-webfonts, 'roboto-light-webfont', 300, 'Roboto Light');
@include font-face('roboto', $path-webfonts, 'roboto-regular-webfont', 400, 'Roboto Regular');
@include font-face('roboto', $path-webfonts, 'roboto-bold-webfont', 700, 'Roboto Bold');
