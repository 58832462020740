::-moz-selection { /* Code for Firefox */
    color: white;
    background: $color--primary;
}

::selection {
    color: white; 
    background: $color--primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto Slab', sans-serif;
    font-weight: $hl-fw--default;
    line-height: $hl-lh--default;
    margin: 0;
}

h1 {
    font-size: $hl--h1;
    hyphens: auto;
    margin-bottom: 1.25rem;
}

h2 {
    font-size: $hl--h2;
    margin-bottom: .9375rem;
}

h3 {
    font-size: $hl--h3;
    margin-bottom: .625rem;
}

h4 {
    font-size: $hl--h4;
    margin-bottom: .3125rem;
}

h5 {
    font-size: $hl--h5;
}

h6 {
    font-size: $hl--h6;
}
