// ==========================================================================
// Mixin :: IconFonts (@include icon(before, 'arrow');)
// ==========================================================================

@mixin icon($position: before, $icon: false, $styles: true) {
    @if $position == both {
        $position: 'before, &:after';
    }

    &:#{$position} {
        @if $icon {
            content: '#{map-get($icons, $icon)}';
        }
        @if $styles {
            display: inline-block;
            font-family: $ff--icons;
            font-size: $fs--default;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            position: relative;
            speak: none;
            text-align: center;
            text-decoration: none;
            width: auto;
            // sass-lint:disable-all
            @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
            }
            // sass-lint:enable-all
        }
        @content;
    }
}

@font-face {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    src: url($path-iconfonts + 'fontello.woff2?8926641') format('woff2'),
    url($path-iconfonts + 'fontello.woff?8926641') format('woff'),
    url($path-iconfonts + 'fontello.ttf?8926641') format('truetype'),
    url($path-iconfonts + 'fontello.svg?8926641#fontello') format('svg');
}

$icons: (
    cancel: '\e800',
    ok: '\e801',
    minus: '\e802',
    search: '\e803',
    plus: '\e817',
    mail-alt: '\f0e0',
    angle-left: '\f104',
    angle-right: '\f105',
    angle-up: '\f106',
    angle-down: '\f107'
);
