.c-optimized {
    background-color: $color--white;
    bottom: 0;
    display: table;
    font-family: Consolas, serif;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    vertical-align: middle;
    width: 100%;
    z-index: 9999;

    &__inner {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }
}
