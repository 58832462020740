.c-text {
    padding: 10rem 0;

    @media screen and (max-width: 767px) {
        padding: 4rem 0;
    }

    &.is--not-padding {
        padding-top: 0;
    }

    &.is--start {
        padding: 0 0 10rem 0;
    }

    &.is--light {
        background: #f7f5ef;
    }

    &__inner {
        max-width: 75%;
        margin: 0 auto;

        &.is--spatz {
            position: relative;
            text-align: center;
        }

        @media screen and (max-width: 767px) {
            max-width: 85%;
        }
    }

    &__link {
        color: $color--primary;
    }

    &__button {
        display: inline-block;
        padding: 1rem 3rem;
        background: $color--primary;
        color: white;
        text-decoration: none;
        font-size: 1.125rem;
        font-family: "Muli", sans-serif;
        transition: 0.35s ease background;
        border-radius: 3px;
        margin: 0 15%;

        &:hover {
            background: darken($color--primary, 10%);
            transition: 0.35s ease background;
        }
    }

    &__paragraph {
        text-align: left;
        font-size: 1.375rem;
        line-height: 1.65;
        margin-left: 15%;
        margin-right: 15%;
        margin-bottom: 2rem;

        @media screen and (max-width: 1440px) {
            margin: 0 0 2rem 0;
        }

        @media screen and (max-width: 767px) {
            font-size: 1rem;
        }

        &.is--padding {
            margin-bottom: 5rem;
        }

        &.is--center {
            text-align: center;
        }
    }

    &__image {
        margin-left: 7.5%;
        margin-right: 7.5%;
        margin-top: 10rem;

        img {
            width: 100%;
        }

        @media screen and (max-width: 1440px) {
            margin: 0;
        }

        &.is--other {
            margin-top: 0;
            margin-bottom: 10rem;

            @media screen and (max-width: 767px) {
                margin-bottom: 3rem;
            }
        }
    }

    &__headline {
        margin-left: 15%;
        margin-right: 15%;
        text-align: left;
        font-size: 2.25rem;
        color: $color--primary;
        margin-bottom: 2rem;

        @media screen and (max-width: 767px) {
            font-size: 1.5rem;
        }

        &.is--big {
            margin-top: 4rem;

            @media screen and (max-width: 767px) {
                margin-top: 2rem;
            }
        }

        &.is--bigger {
            font-size: 3.25rem;
        }

        @media screen and (max-width: 1440px) {
            margin-left: 0;
            margin-right: 0;
        }

        &.is--center {
            text-align: center;
        }

        &.is--black {
            color: #2c2d25 !important;
            text-transform: uppercase;
        }

    }

    &__list {
        margin-left: 15%;
        margin-right: 15%;
        margin-bottom: 2rem;

        @media screen and (max-width: 1440px) {
            margin: 0 0 2rem 0;
        }

        &-item {
            line-height: 2;
            font-size: 1.375rem;

            @media screen and (max-width: 767px) {
                font-size: 1rem;
            }

            &:before {
                content: "";
                display: inline-block;
                height: 0.5rem;
                width: 0.5rem;
                border-radius: 100%;
                vertical-align: 2px;
                margin-right: 1.25rem;
                background: $color--primary;
            }
        }
    }
}

.c-impressum {
    &__inner {
        max-width: 75%;
        margin: 0 auto;
        text-align: center;
    }

    &__block {
        &-text {
            margin-bottom: 3rem;
            font-size: 1.75rem;
            line-height: 1.65;

            @media screen and (max-width: 767px) {
                font-size: 1.15rem;
            }

            a {
                color: $color--primary;
                margin-left: 0.5rem;
                display: inline-block;
            }
        }
    }
}

.c-mitmachen {
    background: $color--primary;
    padding: 10rem 0;
    text-align: center;

    &.is--blue {
        background: #a4d2e8;
    }

    &.is--left {
        text-align: left;

        .c-mitmachen__text {
            text-align: left;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 4rem 0;
    }

    &__inner {
        max-width: 75%;
        margin: 0 auto;

        @media screen and (max-width: 767px) {
            max-width: 85%;
        }
    }

    &__headline {
        font-size: 3rem;
        color: white;
        margin-bottom: 2rem;

        @media screen and (max-width: 767px) {
            font-size: 2rem;
        }
    }

    &__text {
        text-align: center;
        margin-left: 10%;
        margin-right: 10%;
        color: white;
        line-height: 1.65;
        font-size: 2.25rem;

        @media screen and (max-width: 1440px) {
            margin: 0;
        }

        @media screen and (max-width: 767px) {
            font-size: 1.5rem;
        }

        &.margin--bottom {
            margin-bottom: 2rem;
        }
    }

    &__list {
        margin-top: 3.5rem;
        display: block;
        margin-left: 10%;
        margin-right: 10%;

        @media screen and (max-width: 1440px) {
            margin-left: 0;
            margin-right: 0;
        }

        &-item {
            font-size: 2.25rem;
            color: white;
            line-height: 2;

            @media screen and (max-width: 767px) {
                font-size: 1.5rem;
            }

            &:before {
                content: "";
                height: 0.5rem;
                width: 0.5rem;
                display: inline-block;
                vertical-align: middle;
                background: white;
                margin-right: 1.5rem;
                border-radius: 100%;
            }
        }
    }

    &__link {
        margin-top: 3rem;
        margin-left: 1rem;
        margin-right: 1rem;
        display: inline-block;
        font-size: 1.75rem;
        color: white;
        padding: 1rem 3rem;
        border: 3px solid white;
        text-decoration: none;
        transition: 0.35s ease all;

        @media screen and (max-width: 767px) {
            padding: 1rem;
            font-size: 1rem;
            margin-top: 1.5rem;
        }

        &:hover {
            background: white;
            color: $color--primary;
            transition: 0.35s ease all;
        }
    }
}
