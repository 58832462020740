.c-introduction {
    text-align: center;
    padding: 10rem 0;
    position: relative;
    overflow: hidden;
    
    &.is--light {
        background: #f7f5ef;
    }

    &.is--not-padding {
        padding: 0;
    }

    &.is--padding {
        @media screen and (max-width: 1680px){
            padding-top: 1rem;
        }
    }
    
    @media screen and (max-width: 767px){
        padding: 4rem 0;
    }
    
    &__janosch {
        position: absolute;
        left: 5%;
        top: 0;
        bottom: 0;
        height: 100%;
        transform: rotate(-10deg);
        
        @media screen and (max-width: 1680px){
            position: static;
            transform: none;
            height: 24rem;
        }
    }

    &__superspatz {
        height: 65%;
        position: absolute;
        right: -8rem;
        top: 9rem;

        @media screen and (max-width: 1680px){
            height: 24rem;
        }

        @media screen and (max-width: 1680px){
            right: -11rem;
        }

        &.is--left {
            left: -8rem;

            @media screen and (max-width: 1680px){
                left: -11rem;
            }
        }
        
        @media screen and (max-width: 1200px){
            position: static;
            transform: none;
        }
    }

    &__inner {
        max-width: 72rem;
        margin: 0 auto;
        position: relative;
        z-index: 100;
        
        @media screen and (max-width: 1024px){
            max-width: 85%;
        }
    }

    &__headline {
        font-size: 2.25rem;
        color: $color--primary;

        &.is--small {
            font-size: 1.5rem;

            @media screen and (max-width: 767px){
                font-size: 1.2rem;
            }
        }
        
        @media screen and (max-width: 767px){
            font-size: 1.5rem;
        }
    }

    &__text {
        font-size: 1.375rem;
        line-height: 1.65;
        margin: 2rem;
        
        @media screen and (max-width: 767px){
            font-size: 1rem;
            margin: 0;
        }
        
        a {
            color: $color--primary;
            text-decoration: none;
            
            img {
                margin-left: .5rem;
                vertical-align: -3px;
            }
        }
        
        &.is--big {
            margin-top: 4rem;
        }
    }

    &__button {
        display: inline-block;
        background: $color--primary;
        color: white;
        padding: 1rem 3rem;
        text-decoration: none;
        transition: .35s ease background;
        border-radius: 3px;
        font-size: 1.125rem;
        font-family: "Muli";
            
        &:hover {
            background: darken($color--primary,10%);
            transition: .35s ease background;
        }
    }
}
