
// ====================================================================================
// Buttons : Placeholder
// ====================================================================================

%btn--default {
    background-color: $btn-bg-primary;
    color: $btn-color-primary;
    cursor: pointer;
    display: inline-block;
    font-size: $btn-size;
    font-weight: 400;
    outline: 0;
    padding: $btn-padding;
    text-decoration: none;
    transition: $ts--fast;
    user-select: none;

    //active and hover states
    &:hover {
        background-color: $btn-bg-hover;
    }

    &:active {
        background-color: $btn-bg-active;
    }
}

// ====================================================================================
// Buttons : Kind
// ====================================================================================

%btn--disabled {
    @extend %btn--default;
    background-color: $btn-bg-disabled;
    color: $btn-color-disabled;
    cursor: not-allowed;
}

.e-btn {
    @extend %btn--default;

    /** disable state **/
    &--disabled {
        @extend %btn--disabled;
    }
}
