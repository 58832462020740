
// ==========================================================================
// Variables - Global :: Colors
// ==========================================================================

$color--primary: #fbbb0e;
$color--secondary: #2c2d25;

$color--light: #e4e4e4;
$color--medium: #f6f7f8;
$color--dark: #f0f0f0;
$color--darkest: #747474;
$color--disabled: #cacbcd;

$color--white: #fff;
$color--black: #000;

/** reset **/
$color-tap: rgba(0, 0, 0, 0);
$color-trans-1: rgba(0, 0, 0, .1);

$color--info: #d9edf7;
$color--success: #def0d8;
$color--warning: #fcf8e3;
$color--fail: #f2dede;
$color--error: #e74c3c;

// ==========================================================================
// Config - Button
// ==========================================================================

$btn-bg-primary: $color--primary;
$btn-bg-hover: darken($btn-bg-primary, 5%);
$btn-bg-active: darken($btn-bg-primary, 10%);
$btn-bg-disabled: $color--disabled;

$btn-color-primary: $color--white;
$btn-color-disabled: lighten($color--secondary, 30%);

$btn-padding: .625rem 1.5rem;

$btn-size: 1.125rem;

// ==========================================================================
// Variables - Background
// ==========================================================================

$bg--default: $color--white;

// ==========================================================================
// Variables - Paths
// ==========================================================================

$path-webfonts: '/assets/fonts/webfont/';
$path-iconfonts: '/assets/fonts/iconfont/';

// ==========================================================================
// Variables - Typography
// ==========================================================================

$ff--default: 'Muli', arial, helvetica, sans-serif;
$ff--icons: 'fontello';
$fs--default: 1rem; //16px
$fh--default: 1.15;
$fw--default: 400;

// ==========================================================================
// Variables - Headlines
// ==========================================================================

$hl--h1: 2.125rem; //34px
$hl--h2: 1.625rem; //26px
$hl--h3: 1.25rem; //20px
$hl--h4: 1.125rem; //18px
$hl--h5: 1.063rem; //17px
$hl--h6: 1.063rem; //17px

$hl-lh--default: 1.4;
$hl-fw--default: 400;

// ==========================================================================
// Config - Form
// ==========================================================================

$bg-form-primary: $color--white;
$bg-form-focus: #f9f9f9;

$border-form-primary: #e4e4e4;
$border-form-secondary: #cacbcd;

$color-form-action: $color--primary;
$color-form-success: #2ecc71;
$color-form-error: #e74c3c;

// ==========================================================================
// Variables - Transition
// ==========================================================================

$ts--fast: all .3s;
$ts--cubic: all .3s cubic-bezier(.25, .8, .25, 1);
$ts--slow: all .6s;

// ==========================================================================
// Variables - Loader Duration
// ==========================================================================

$duration--fast: .25s;
$duration--default: .5s;
$duration--slow: 1s;

// ==========================================================================
// Variables - Module :: Content
// ==========================================================================

$md-padding--default: 1.25rem;
$md-margin--default: 1.25rem;

// ==========================================================================
// Variables - Flexbox :: Grid
// ==========================================================================

$fg-width: 90rem;
$fg-columns: 12 !default;
$fg-gutter: .625rem !default;
$fg-class-row: 'o-row' !default;
$fg-class-col: 'o-col' !default;
$fg-class-off: 'o-off' !default;
$fg-breakpoints: (
    (__hh, 20rem), (__xs, 35.5rem), (__sm, 48rem), (__md, 64rem), (__lg, 80rem)
) !default;

// ==========================================================================
// Variables - MediaQueries :: Grid @include media(">small") {}
// ==========================================================================

$breakpoints: (
    'handheld': 21.5rem, // 340px
    'mobile': 33.5rem, //536px
    'xsmall': 40rem, // 767px
    'small': 48rem, // 768px
    'medium': 64rem, // 1024px
    'wide': 80rem, // 1280px
    'large': 90rem, // 1440px
    'screen': 95rem, // 1520px
    'xlarge': 100rem // 1600px
);
