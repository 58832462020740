/** reset **/
/** grid generate rows **/
.o-row {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

/** grid generate cols **/
.o-col__hh, .o-col__xs, .o-col__sm, .o-col__md, .o-col__lg, .o-col__hh-1, .o-col__hh-2, .o-col__hh-3, .o-col__hh-4, .o-col__hh-5, .o-col__hh-6, .o-col__hh-7, .o-col__hh-8, .o-col__hh-9, .o-col__hh-10, .o-col__hh-11, .o-col__hh-12, .o-col__xs-1, .o-col__xs-2, .o-col__xs-3, .o-col__xs-4, .o-col__xs-5, .o-col__xs-6, .o-col__xs-7, .o-col__xs-8, .o-col__xs-9, .o-col__xs-10, .o-col__xs-11, .o-col__xs-12, .o-col__sm-1, .o-col__sm-2, .o-col__sm-3, .o-col__sm-4, .o-col__sm-5, .o-col__sm-6, .o-col__sm-7, .o-col__sm-8, .o-col__sm-9, .o-col__sm-10, .o-col__sm-11, .o-col__sm-12, .o-col__md-1, .o-col__md-2, .o-col__md-3, .o-col__md-4, .o-col__md-5, .o-col__md-6, .o-col__md-7, .o-col__md-8, .o-col__md-9, .o-col__md-10, .o-col__md-11, .o-col__md-12, .o-col__lg-1, .o-col__lg-2, .o-col__lg-3, .o-col__lg-4, .o-col__lg-5, .o-col__lg-6, .o-col__lg-7, .o-col__lg-8, .o-col__lg-9, .o-col__lg-10, .o-col__lg-11, .o-col__lg-12 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  /** flex direction hack for safari 9 **/
}

@supports (overflow: -webkit-marquee) and (justify-content: inherit) {
  .o-col__hh, .o-col__xs, .o-col__sm, .o-col__md, .o-col__lg, .o-col__hh-1, .o-col__hh-2, .o-col__hh-3, .o-col__hh-4, .o-col__hh-5, .o-col__hh-6, .o-col__hh-7, .o-col__hh-8, .o-col__hh-9, .o-col__hh-10, .o-col__hh-11, .o-col__hh-12, .o-col__xs-1, .o-col__xs-2, .o-col__xs-3, .o-col__xs-4, .o-col__xs-5, .o-col__xs-6, .o-col__xs-7, .o-col__xs-8, .o-col__xs-9, .o-col__xs-10, .o-col__xs-11, .o-col__xs-12, .o-col__sm-1, .o-col__sm-2, .o-col__sm-3, .o-col__sm-4, .o-col__sm-5, .o-col__sm-6, .o-col__sm-7, .o-col__sm-8, .o-col__sm-9, .o-col__sm-10, .o-col__sm-11, .o-col__sm-12, .o-col__md-1, .o-col__md-2, .o-col__md-3, .o-col__md-4, .o-col__md-5, .o-col__md-6, .o-col__md-7, .o-col__md-8, .o-col__md-9, .o-col__md-10, .o-col__md-11, .o-col__md-12, .o-col__lg-1, .o-col__lg-2, .o-col__lg-3, .o-col__lg-4, .o-col__lg-5, .o-col__lg-6, .o-col__lg-7, .o-col__lg-8, .o-col__lg-9, .o-col__lg-10, .o-col__lg-11, .o-col__lg-12 {
    flex-direction: initial;
    flex-wrap: wrap;
  }
}

.o-col__hh, .o-col__xs, .o-col__sm, .o-col__md, .o-col__lg {
  flex-basis: 100%;
  flex-grow: 1;
  max-width: 100%;
}

@media only screen and (min-width: 20rem) {
  .o-col__hh-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .o-col__hh-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .o-col__hh-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-col__hh-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .o-col__hh-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .o-col__hh-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-col__hh-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .o-col__hh-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .o-col__hh-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-col__hh-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .o-col__hh-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .o-col__hh-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .o-off__hh-1 {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }
  .o-off__hh-2 {
    padding-left: 16.66667%;
    padding-right: 16.66667%;
  }
  .o-off__hh-3 {
    padding-left: 25%;
    padding-right: 25%;
  }
  .o-off__hh-4 {
    padding-left: 33.33333%;
    padding-right: 33.33333%;
  }
  .o-off__hh-5 {
    padding-left: 41.66667%;
    padding-right: 41.66667%;
  }
  .o-off__hh-6 {
    padding-left: 50%;
    padding-right: 50%;
  }
  .o-off__hh-7 {
    padding-left: 58.33333%;
    padding-right: 58.33333%;
  }
  .o-off__hh-8 {
    padding-left: 66.66667%;
    padding-right: 66.66667%;
  }
  .o-off__hh-9 {
    padding-left: 75%;
    padding-right: 75%;
  }
  .o-off__hh-10 {
    padding-left: 83.33333%;
    padding-right: 83.33333%;
  }
  .o-off__hh-11 {
    padding-left: 91.66667%;
    padding-right: 91.66667%;
  }
}

@media only screen and (min-width: 35.5rem) {
  .o-col__xs-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .o-col__xs-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .o-col__xs-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-col__xs-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .o-col__xs-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .o-col__xs-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-col__xs-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .o-col__xs-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .o-col__xs-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-col__xs-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .o-col__xs-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .o-col__xs-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .o-off__xs-1 {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }
  .o-off__xs-2 {
    padding-left: 16.66667%;
    padding-right: 16.66667%;
  }
  .o-off__xs-3 {
    padding-left: 25%;
    padding-right: 25%;
  }
  .o-off__xs-4 {
    padding-left: 33.33333%;
    padding-right: 33.33333%;
  }
  .o-off__xs-5 {
    padding-left: 41.66667%;
    padding-right: 41.66667%;
  }
  .o-off__xs-6 {
    padding-left: 50%;
    padding-right: 50%;
  }
  .o-off__xs-7 {
    padding-left: 58.33333%;
    padding-right: 58.33333%;
  }
  .o-off__xs-8 {
    padding-left: 66.66667%;
    padding-right: 66.66667%;
  }
  .o-off__xs-9 {
    padding-left: 75%;
    padding-right: 75%;
  }
  .o-off__xs-10 {
    padding-left: 83.33333%;
    padding-right: 83.33333%;
  }
  .o-off__xs-11 {
    padding-left: 91.66667%;
    padding-right: 91.66667%;
  }
}

@media only screen and (min-width: 48rem) {
  .o-col__sm-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .o-col__sm-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .o-col__sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-col__sm-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .o-col__sm-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .o-col__sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-col__sm-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .o-col__sm-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .o-col__sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-col__sm-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .o-col__sm-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .o-col__sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .o-off__sm-1 {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }
  .o-off__sm-2 {
    padding-left: 16.66667%;
    padding-right: 16.66667%;
  }
  .o-off__sm-3 {
    padding-left: 25%;
    padding-right: 25%;
  }
  .o-off__sm-4 {
    padding-left: 33.33333%;
    padding-right: 33.33333%;
  }
  .o-off__sm-5 {
    padding-left: 41.66667%;
    padding-right: 41.66667%;
  }
  .o-off__sm-6 {
    padding-left: 50%;
    padding-right: 50%;
  }
  .o-off__sm-7 {
    padding-left: 58.33333%;
    padding-right: 58.33333%;
  }
  .o-off__sm-8 {
    padding-left: 66.66667%;
    padding-right: 66.66667%;
  }
  .o-off__sm-9 {
    padding-left: 75%;
    padding-right: 75%;
  }
  .o-off__sm-10 {
    padding-left: 83.33333%;
    padding-right: 83.33333%;
  }
  .o-off__sm-11 {
    padding-left: 91.66667%;
    padding-right: 91.66667%;
  }
}

@media only screen and (min-width: 64rem) {
  .o-col__md-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .o-col__md-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .o-col__md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-col__md-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .o-col__md-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .o-col__md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-col__md-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .o-col__md-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .o-col__md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-col__md-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .o-col__md-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .o-col__md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .o-off__md-1 {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }
  .o-off__md-2 {
    padding-left: 16.66667%;
    padding-right: 16.66667%;
  }
  .o-off__md-3 {
    padding-left: 25%;
    padding-right: 25%;
  }
  .o-off__md-4 {
    padding-left: 33.33333%;
    padding-right: 33.33333%;
  }
  .o-off__md-5 {
    padding-left: 41.66667%;
    padding-right: 41.66667%;
  }
  .o-off__md-6 {
    padding-left: 50%;
    padding-right: 50%;
  }
  .o-off__md-7 {
    padding-left: 58.33333%;
    padding-right: 58.33333%;
  }
  .o-off__md-8 {
    padding-left: 66.66667%;
    padding-right: 66.66667%;
  }
  .o-off__md-9 {
    padding-left: 75%;
    padding-right: 75%;
  }
  .o-off__md-10 {
    padding-left: 83.33333%;
    padding-right: 83.33333%;
  }
  .o-off__md-11 {
    padding-left: 91.66667%;
    padding-right: 91.66667%;
  }
}

@media only screen and (min-width: 80rem) {
  .o-col__lg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  .o-col__lg-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  .o-col__lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .o-col__lg-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  .o-col__lg-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  .o-col__lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .o-col__lg-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  .o-col__lg-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  .o-col__lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .o-col__lg-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  .o-col__lg-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  .o-col__lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .o-off__lg-1 {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }
  .o-off__lg-2 {
    padding-left: 16.66667%;
    padding-right: 16.66667%;
  }
  .o-off__lg-3 {
    padding-left: 25%;
    padding-right: 25%;
  }
  .o-off__lg-4 {
    padding-left: 33.33333%;
    padding-right: 33.33333%;
  }
  .o-off__lg-5 {
    padding-left: 41.66667%;
    padding-right: 41.66667%;
  }
  .o-off__lg-6 {
    padding-left: 50%;
    padding-right: 50%;
  }
  .o-off__lg-7 {
    padding-left: 58.33333%;
    padding-right: 58.33333%;
  }
  .o-off__lg-8 {
    padding-left: 66.66667%;
    padding-right: 66.66667%;
  }
  .o-off__lg-9 {
    padding-left: 75%;
    padding-right: 75%;
  }
  .o-off__lg-10 {
    padding-left: 83.33333%;
    padding-right: 83.33333%;
  }
  .o-off__lg-11 {
    padding-left: 91.66667%;
    padding-right: 91.66667%;
  }
}

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto Light"), url("/assets/fonts/webfont/roboto-light-webfont.woff2") format("woff2"), url("/assets/fonts/webfont/roboto-light-webfont.woff") format("woff");
}

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Regular"), local("Roboto Regular"), url("/assets/fonts/webfont/roboto-regular-webfont.woff2") format("woff2"), url("/assets/fonts/webfont/roboto-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto Bold"), url("/assets/fonts/webfont/roboto-bold-webfont.woff2") format("woff2"), url("/assets/fonts/webfont/roboto-bold-webfont.woff") format("woff");
}

html {
  -ms-overflow-style: scrollbar;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

*::before, *::after {
  box-sizing: inherit;
}

*::-moz-selection {
  background: #2c2d25;
  color: #fff;
}

*::selection {
  background: #2c2d25;
  color: #fff;
}

:root {
  tab-size: 4;
}

html,
body {
  font-size: 100%;
  height: 100%;
  width: 100%;
}

body {
  background-color: #fff;
  color: #2c2d25;
  font-family: "Muli", arial, helvetica, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.15;
  margin: 0;
  text-rendering: optimizeSpeed;
}

@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
}

@-ms-viewport {
  width: device-width;
}

.no-transition *,
.no-transition *:before,
.no-transition *:after {
  transition: none !important;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

[role='button'] {
  cursor: pointer;
}

a,
area,
button,
[role='button'],
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

input:not([type='range']) {
  touch-action: manipulation;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

figure {
  margin: 0;
}

video {
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

p,
dd,
dl,
ul,
ol,
ul ul,
ol ul {
  color: #2c2d25;
  font-size: "Muli", arial, helvetica, sans-serif;
  margin: 0;
  padding: 0;
  text-rendering: optimizeSpeed;
}

dfn,
i,
em {
  font-style: italic;
}

del {
  text-decoration: line-through;
}

li {
  list-style: none;
  padding: 0;
}

b,
strong {
  font-weight: 700;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: transparent;
  outline: 0;
}

a:active, a:hover {
  outline-width: 0;
}

button,
input,
optgroup,
select,
textarea {
  border: 0;
  font: inherit;
  line-height: inherit;
  margin: 0;
  text-rendering: optimizeSpeed;
}

button,
input {
  overflow: visible;
}

button,
html [type='button'],
[type='reset'],
[type='submit'],
[type='email'],
[type='password'],
[type='number'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type='button']:focus, [type='button']:-moz-focusring,
[type='reset']:focus,
[type='reset']:-moz-focusring,
[type='submit']:focus,
[type='submit']:-moz-focusring {
  outline: 0 none !important;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -.125rem;
}

[type='search']::-webkit-search-decoration, [type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration,
[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type='number']::-webkit-outer-spin-button, [type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button,
[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  height: auto;
  margin: 0;
}

[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: #2c2d25;
  opacity: 1;
}

select,
option {
  font-family: arial, helvetica, sans-serif;
}

button,
select {
  text-transform: none;
}

select {
  width: 100%;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  font-size: 1rem;
  line-height: inherit;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}

textarea {
  font-size: 1rem;
  overflow: auto;
  resize: vertical;
}

pre {
  background-color: #2c2d25;
  color: #fff;
  display: inline-block;
  margin: 0;
  padding: .3125rem .625rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

summary {
  display: list-item;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  user-select: none;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

@font-face {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/iconfont/fontello.woff2?8926641") format("woff2"), url("/assets/fonts/iconfont/fontello.woff?8926641") format("woff"), url("/assets/fonts/iconfont/fontello.ttf?8926641") format("truetype"), url("/assets/fonts/iconfont/fontello.svg?8926641#fontello") format("svg");
}

@media print {
  *::after, *::before, *::first-letter, *::first-line,
  p::after,
  p::before,
  p::first-letter,
  p::first-line,
  div::after,
  div::before,
  div::first-letter,
  div::first-line,
  blockquote::after,
  blockquote::before,
  blockquote::first-letter,
  blockquote::first-line,
  li::after,
  li::before,
  li::first-letter,
  li::first-line,
  p::after,
  p::before,
  p::first-letter,
  p::first-line,
  div::after,
  div::before,
  div::first-letter,
  div::first-line,
  blockquote::after,
  blockquote::before,
  blockquote::first-letter,
  blockquote::first-line,
  li::after,
  li::before,
  li::first-letter,
  li::first-line {
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a {
    text-decoration: underline;
  }
  a:visited {
    text-decoration: underline;
  }
  abbr[title] ::after {
    content: '("attr(title)")';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 0.0625rem solid #000;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  table {
    border-collapse: collapse;
  }
  table td,
  table th {
    background-color: #fff;
  }
  /* Custom */
  .c-nav,
  .c-footer {
    display: none;
  }
}

.o-content {
  flex: 0 0 auto;
  padding: 1.25rem;
}

.o-content--margin {
  margin-bottom: 1.25rem;
}

.o-content--match {
  align-items: stretch;
  flex: 1 auto;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .o-content--match {
    height: calc(100% - 1.25rem);
  }
}

.o-content > *:last-child {
  margin-bottom: 0;
}

.o-row {
  margin: 0 auto;
  max-width: 90rem;
  /** Full width **/
  /** Remove inner padding **/
  /** Nested **/
}

.o-row__stretch {
  margin: 0;
  max-width: 100%;
}

.o-row__collapse > [class^='o-col__'] {
  padding-left: 0;
  padding-right: 0;
}

.o-row .o-row {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  max-width: none;
  width: auto;
}

/** spinning animation **/
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.e-btn--disabled, .e-btn {
  background-color: #fbbb0e;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 400;
  outline: 0;
  padding: 0.625rem 1.5rem;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
}

.e-btn--disabled:hover, .e-btn:hover {
  background-color: #ecad04;
}

.e-btn--disabled:active, .e-btn:active {
  background-color: #d39b03;
}

.e-btn--disabled {
  background-color: #cacbcd;
  color: #7e816a;
  cursor: not-allowed;
}

.e-btn {
  /** disable state **/
}

.e-form {
  width: 100%;
  /** form wrapper(grid) **/
  /** form fieldset and legend **/
  /** form labels **/
  /** form fields **/
}

.e-form__group {
  display: flex;
  flex-direction: row;
  margin: 0 -0.625rem 1.25rem;
}

.e-form__wrap {
  clear: both;
  flex: 0 1 auto;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  position: relative;
  width: 100%;
}

.e-form__wrap.is--half {
  width: 50%;
}

.e-form > *:last-child {
  margin-bottom: 0;
}

.e-form__fieldset {
  margin-bottom: 2.5rem;
}

.e-form__fieldset > *:last-child {
  margin-bottom: 0;
}

.e-form__legend {
  border-bottom: 0.0625rem solid #cacbcd;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1.875rem;
  padding-bottom: .9375rem;
}

.e-form__label {
  display: block;
  margin-bottom: .3125rem;
  /** radio & checkbox **/
}

.e-form__label.is--checkbox, .e-form__label.is--radio {
  cursor: pointer;
  padding: .25rem 0 0 2.5rem;
  position: relative;
}

.e-form__label.is--checkbox:before, .e-form__label.is--radio:before {
  background-color: #fff;
  border: 0.0625rem solid #e4e4e4;
  content: '';
  display: inline-block;
  height: 1.875rem;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  width: 1.875rem;
  z-index: 1;
}

.e-form__label.is--checkbox:hover:before, .e-form__label.is--radio:hover:before {
  background-color: #f9f9f9;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);
}

.e-form__label.is--radio:before {
  border-radius: 50%;
}

.e-form__label.is--checkbox:after {
  background: transparent;
  border: 0.1875rem solid #fff;
  border-right: 0;
  border-top: 0;
  content: '';
  display: inline-block;
  height: .625rem;
  left: .3125rem;
  opacity: 0;
  position: absolute;
  top: .375rem;
  transform: rotate(-45deg);
  transition: all 0.3s;
  width: 1.25rem;
  z-index: 2;
}

.e-form__field {
  background-color: #fff;
  border: 0.0625rem solid #e4e4e4;
  box-shadow: none;
  height: 2.875rem;
  outline: none;
  padding: 0 .9375rem;
  transition: all 0.3s;
  width: 100%;
  /** radio & checkbox **/
  /** searchfield **/
  /** textarea **/
}

.e-form__field:hover {
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);
}

.e-form__field:focus {
  background-color: #f9f9f9;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);
  outline: none;
}

.e-form__field:focus:valid {
  border-color: #2ecc71;
}

.e-form__field:focus:invalid {
  border-color: #e74c3c;
}

.e-form__field-radio, .e-form__field-checkbox {
  left: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
}

.e-form__field-radio:checked + .is--checkbox:before, .e-form__field-checkbox:checked + .is--checkbox:before {
  background-color: #fbbb0e;
  border-color: #fff;
}

.e-form__field-radio:checked + .is--checkbox:after, .e-form__field-checkbox:checked + .is--checkbox:after {
  opacity: 1;
}

.e-form__field-radio:checked + .is--radio:before, .e-form__field-checkbox:checked + .is--radio:before {
  background-color: #fbbb0e;
  border-color: #b98803;
  border-width: .1875rem;
}

.e-form__field.is--search {
  float: left;
  width: calc(100% - 2.875rem);
}

.e-form__field.is--area {
  height: 12.5rem;
  padding-bottom: .9375rem;
  padding-top: .9375rem;
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #fbbb0e;
}

::selection {
  color: white;
  background: #fbbb0e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Slab', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
}

h1 {
  font-size: 2.125rem;
  hyphens: auto;
  margin-bottom: 1.25rem;
}

h2 {
  font-size: 1.625rem;
  margin-bottom: .9375rem;
}

h3 {
  font-size: 1.25rem;
  margin-bottom: .625rem;
}

h4 {
  font-size: 1.125rem;
  margin-bottom: .3125rem;
}

h5 {
  font-size: 1.063rem;
}

h6 {
  font-size: 1.063rem;
}

/* ------------ VIDEO MODUL ------------ */
.c-video {
  margin: 2rem auto;
  width: 75%;
}

.c-video__wrapper {
  border-radius: .375rem;
  height: 0;
  position: relative;
  width: 100%;
}

.c-video__wrapper-43 {
  padding-top: 75%;
}

.c-video__wrapper-169 {
  padding-top: 56%;
}

.c-video__iframe {
  background-color: transparent;
  border-radius: .3125rem;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.c-optimized {
  background-color: #fff;
  bottom: 0;
  display: table;
  font-family: Consolas, serif;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  vertical-align: middle;
  width: 100%;
  z-index: 9999;
}

.c-optimized__inner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.c-header {
  width: 100%;
}

.is--nostage {
  margin-top: 13rem;
}

@media screen and (max-width: 767px) {
  .is--nostage {
    margin-top: 8rem;
  }
}

.c-stage {
  background: #52625f;
  height: 100vh;
  position: relative;
  width: 100%;
}

.c-stage__text {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 66.6666%;
  transform: translate(-50%, -50%);
  width: 75%;
}

.c-stage__text-headline {
  color: white;
  font-size: 3.25rem;
  margin-bottom: 1rem;
  text-shadow: 0 7px 8px black;
}

@media screen and (max-width: 767px) {
  .c-stage__text-headline {
    font-size: 1.75rem;
  }
}

.c-stage__text-headline.is--center {
  color: white;
  font-size: 3.25rem;
  text-align: center;
  text-shadow: 0 1px 1px black;
}

@media screen and (max-width: 767px) {
  .c-stage__text-headline.is--center {
    font-size: 1.75rem;
  }
}

.c-stage__text-subline {
  color: white;
  margin-bottom: 2rem;
  text-shadow: 0 3px 8px rgba(0, 0, 0, 0.35);
}

.c-stage__text-button {
  background: #fbbb0e;
  border-radius: 3px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  color: white;
  display: inline-block;
  font-family: "Muli";
  font-size: 1.125rem;
  padding: 1rem 3rem;
  text-decoration: none;
  transition: 0.35s ease background;
}

.c-stage__text-button:hover {
  background: #d39b03;
  transition: 0.35s ease background;
}

.c-text {
  padding: 10rem 0;
}

@media screen and (max-width: 767px) {
  .c-text {
    padding: 4rem 0;
  }
}

.c-text.is--not-padding {
  padding-top: 0;
}

.c-text.is--start {
  padding: 0 0 10rem 0;
}

.c-text.is--light {
  background: #f7f5ef;
}

.c-text__inner {
  max-width: 75%;
  margin: 0 auto;
}

.c-text__inner.is--spatz {
  position: relative;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-text__inner {
    max-width: 85%;
  }
}

.c-text__link {
  color: #fbbb0e;
}

.c-text__button {
  display: inline-block;
  padding: 1rem 3rem;
  background: #fbbb0e;
  color: white;
  text-decoration: none;
  font-size: 1.125rem;
  font-family: "Muli", sans-serif;
  transition: 0.35s ease background;
  border-radius: 3px;
  margin: 0 15%;
}

.c-text__button:hover {
  background: #d39b03;
  transition: 0.35s ease background;
}

.c-text__paragraph {
  text-align: left;
  font-size: 1.375rem;
  line-height: 1.65;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1440px) {
  .c-text__paragraph {
    margin: 0 0 2rem 0;
  }
}

@media screen and (max-width: 767px) {
  .c-text__paragraph {
    font-size: 1rem;
  }
}

.c-text__paragraph.is--padding {
  margin-bottom: 5rem;
}

.c-text__paragraph.is--center {
  text-align: center;
}

.c-text__image {
  margin-left: 7.5%;
  margin-right: 7.5%;
  margin-top: 10rem;
}

.c-text__image img {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .c-text__image {
    margin: 0;
  }
}

.c-text__image.is--other {
  margin-top: 0;
  margin-bottom: 10rem;
}

@media screen and (max-width: 767px) {
  .c-text__image.is--other {
    margin-bottom: 3rem;
  }
}

.c-text__headline {
  margin-left: 15%;
  margin-right: 15%;
  text-align: left;
  font-size: 2.25rem;
  color: #fbbb0e;
  margin-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .c-text__headline {
    font-size: 1.5rem;
  }
}

.c-text__headline.is--big {
  margin-top: 4rem;
}

@media screen and (max-width: 767px) {
  .c-text__headline.is--big {
    margin-top: 2rem;
  }
}

.c-text__headline.is--bigger {
  font-size: 3.25rem;
}

@media screen and (max-width: 1440px) {
  .c-text__headline {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-text__headline.is--center {
  text-align: center;
}

.c-text__headline.is--black {
  color: #2c2d25 !important;
  text-transform: uppercase;
}

.c-text__list {
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1440px) {
  .c-text__list {
    margin: 0 0 2rem 0;
  }
}

.c-text__list-item {
  line-height: 2;
  font-size: 1.375rem;
}

@media screen and (max-width: 767px) {
  .c-text__list-item {
    font-size: 1rem;
  }
}

.c-text__list-item:before {
  content: "";
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  vertical-align: 2px;
  margin-right: 1.25rem;
  background: #fbbb0e;
}

.c-impressum__inner {
  max-width: 75%;
  margin: 0 auto;
  text-align: center;
}

.c-impressum__block-text {
  margin-bottom: 3rem;
  font-size: 1.75rem;
  line-height: 1.65;
}

@media screen and (max-width: 767px) {
  .c-impressum__block-text {
    font-size: 1.15rem;
  }
}

.c-impressum__block-text a {
  color: #fbbb0e;
  margin-left: 0.5rem;
  display: inline-block;
}

.c-mitmachen {
  background: #fbbb0e;
  padding: 10rem 0;
  text-align: center;
}

.c-mitmachen.is--blue {
  background: #a4d2e8;
}

.c-mitmachen.is--left {
  text-align: left;
}

.c-mitmachen.is--left .c-mitmachen__text {
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-mitmachen {
    padding: 4rem 0;
  }
}

.c-mitmachen__inner {
  max-width: 75%;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .c-mitmachen__inner {
    max-width: 85%;
  }
}

.c-mitmachen__headline {
  font-size: 3rem;
  color: white;
  margin-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .c-mitmachen__headline {
    font-size: 2rem;
  }
}

.c-mitmachen__text {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  color: white;
  line-height: 1.65;
  font-size: 2.25rem;
}

@media screen and (max-width: 1440px) {
  .c-mitmachen__text {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-mitmachen__text {
    font-size: 1.5rem;
  }
}

.c-mitmachen__text.margin--bottom {
  margin-bottom: 2rem;
}

.c-mitmachen__list {
  margin-top: 3.5rem;
  display: block;
  margin-left: 10%;
  margin-right: 10%;
}

@media screen and (max-width: 1440px) {
  .c-mitmachen__list {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-mitmachen__list-item {
  font-size: 2.25rem;
  color: white;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .c-mitmachen__list-item {
    font-size: 1.5rem;
  }
}

.c-mitmachen__list-item:before {
  content: "";
  height: 0.5rem;
  width: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  background: white;
  margin-right: 1.5rem;
  border-radius: 100%;
}

.c-mitmachen__link {
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: inline-block;
  font-size: 1.75rem;
  color: white;
  padding: 1rem 3rem;
  border: 3px solid white;
  text-decoration: none;
  transition: 0.35s ease all;
}

@media screen and (max-width: 767px) {
  .c-mitmachen__link {
    padding: 1rem;
    font-size: 1rem;
    margin-top: 1.5rem;
  }
}

.c-mitmachen__link:hover {
  background: white;
  color: #fbbb0e;
  transition: 0.35s ease all;
}

.c-workshop {
  padding: 10rem 0;
  text-align: left;
}

.c-workshop__inner {
  max-width: 75%;
  margin: 0 22.5%;
}

@media screen and (max-width: 767px) {
  .c-workshop__inner {
    max-width: 85%;
  }
}

.c-workshop__headline {
  font-size: 2.25rem;
  color: #fbbb0e;
}

@media screen and (max-width: 767px) {
  .c-workshop__headline {
    font-size: 1.5rem;
  }
}

.c-workshop__list {
  margin-top: 4rem;
  text-align: left;
}

.c-workshop__list-item {
  background: #f7f5ef;
  padding: 3rem;
  display: flex;
  flex: 1 1;
  margin-bottom: 1rem;
}

@media screen and (max-width: 767px) {
  .c-workshop__list-item {
    flex: none;
    display: block;
    text-align: center;
    padding: 1.75rem;
  }
}

.c-workshop__date {
  text-align: left;
  display: inline-block;
  display: block;
  width: 50%;
  font-size: 1.75rem;
  align-self: center;
}

@media screen and (max-width: 767px) {
  .c-workshop__date {
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
  }
}

.c-workshop__button {
  display: block;
  width: 50%;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .c-workshop__button {
    width: 100%;
    text-align: center;
  }
}

.c-workshop__button a {
  display: inline-block;
  padding: 1rem 3rem;
  background: #fbbb0e;
  color: white;
  text-decoration: none;
  font-size: 1.125rem;
  font-family: "Muli", sans-serif;
  transition: .35s ease background;
  border-radius: 3px;
}

.c-workshop__button a:hover {
  background: #d39b03;
  transition: .35s ease background;
}

.c-introduction {
  text-align: center;
  padding: 10rem 0;
  position: relative;
  overflow: hidden;
}

.c-introduction.is--light {
  background: #f7f5ef;
}

.c-introduction.is--not-padding {
  padding: 0;
}

@media screen and (max-width: 1680px) {
  .c-introduction.is--padding {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .c-introduction {
    padding: 4rem 0;
  }
}

.c-introduction__janosch {
  position: absolute;
  left: 5%;
  top: 0;
  bottom: 0;
  height: 100%;
  transform: rotate(-10deg);
}

@media screen and (max-width: 1680px) {
  .c-introduction__janosch {
    position: static;
    transform: none;
    height: 24rem;
  }
}

.c-introduction__superspatz {
  height: 65%;
  position: absolute;
  right: -8rem;
  top: 9rem;
}

@media screen and (max-width: 1680px) {
  .c-introduction__superspatz {
    height: 24rem;
  }
}

@media screen and (max-width: 1680px) {
  .c-introduction__superspatz {
    right: -11rem;
  }
}

.c-introduction__superspatz.is--left {
  left: -8rem;
}

@media screen and (max-width: 1680px) {
  .c-introduction__superspatz.is--left {
    left: -11rem;
  }
}

@media screen and (max-width: 1200px) {
  .c-introduction__superspatz {
    position: static;
    transform: none;
  }
}

.c-introduction__inner {
  max-width: 72rem;
  margin: 0 auto;
  position: relative;
  z-index: 100;
}

@media screen and (max-width: 1024px) {
  .c-introduction__inner {
    max-width: 85%;
  }
}

.c-introduction__headline {
  font-size: 2.25rem;
  color: #fbbb0e;
}

.c-introduction__headline.is--small {
  font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
  .c-introduction__headline.is--small {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .c-introduction__headline {
    font-size: 1.5rem;
  }
}

.c-introduction__text {
  font-size: 1.375rem;
  line-height: 1.65;
  margin: 2rem;
}

@media screen and (max-width: 767px) {
  .c-introduction__text {
    font-size: 1rem;
    margin: 0;
  }
}

.c-introduction__text a {
  color: #fbbb0e;
  text-decoration: none;
}

.c-introduction__text a img {
  margin-left: .5rem;
  vertical-align: -3px;
}

.c-introduction__text.is--big {
  margin-top: 4rem;
}

.c-introduction__button {
  display: inline-block;
  background: #fbbb0e;
  color: white;
  padding: 1rem 3rem;
  text-decoration: none;
  transition: .35s ease background;
  border-radius: 3px;
  font-size: 1.125rem;
  font-family: "Muli";
}

.c-introduction__button:hover {
  background: #d39b03;
  transition: .35s ease background;
}

.c-initiative {
  background: #f7f5ef;
  padding: 10rem 0;
  position: relative;
  overflow: hidden;
}

.c-initiative.is--yellow {
  padding: 15rem 0;
  background: #fbbb0e;
}

.c-initiative.is--yellow .c-initiative__headline,
.c-initiative.is--yellow .c-initiative__text,
.c-initiative.is--yellow .c-initiative__list-item {
  color: white;
}

@media screen and (max-width: 767px) {
  .c-initiative.is--yellow {
    padding: 5rem 0;
  }
}

.c-initiative.is--nobg {
  background: transparent;
}

@media screen and (max-width: 767px) {
  .c-initiative {
    padding: 4rem 0;
  }
}

.c-initiative.is--white {
  background: white;
}

.c-initiative__video {
  position: absolute;
  padding-top: 30px;
  height: 0;
  width: 75%;
  padding-bottom: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

@media screen and (max-width: 1440px) {
  .c-initiative__video {
    position: relative;
    transform: none;
    padding-bottom: 56.25%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.c-initiative__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 40px 100px rgba(0, 0, 0, 0.15);
}

.c-initiative__inner {
  max-width: 75%;
  margin: 0 auto;
  display: flex;
  flex: 2;
}

@media screen and (max-width: 1440px) {
  .c-initiative__inner {
    display: block;
    flex: none;
  }
}

@media screen and (max-width: 767px) {
  .c-initiative__inner {
    max-width: 85%;
  }
}

.c-initiative__headline {
  color: #fbbb0e;
  font-size: 1.75rem;
  margin-bottom: 2rem;
}

.c-initiative__text {
  font-size: 1.375rem;
  line-height: 1.65;
  margin-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .c-initiative__text {
    font-size: 1rem;
  }
}

.c-initiative__left {
  width: 50%;
}

@media screen and (max-width: 1440px) {
  .c-initiative__left {
    width: 100%;
  }
}

.c-initiative__collage {
  position: absolute;
  right: 0%;
  left: 52%;
  top: 0;
  bottom: 0;
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .c-initiative__collage {
    width: 100%;
    position: static;
    margin-top: 5rem;
  }
}

.c-initiative__collage img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  box-shadow: 0 40px 100px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1440px) {
  .c-initiative__collage img {
    width: 100%;
    position: static;
    transform: none;
  }
}

.c-initiative__button {
  display: inline-block;
  background: #fbbb0e;
  color: white;
  padding: 1rem 3rem;
  text-decoration: none;
  transition: .35s ease background;
  border-radius: 3px;
  font-size: 1.125rem;
  font-family: "Muli";
}

.c-initiative__button:hover {
  background: #d39b03;
  transition: .35s ease background;
}

.c-initiative__list {
  font-size: 1.375rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .c-initiative__list {
    font-size: 1rem;
  }
}

.c-initiative__list-item {
  line-height: 1.65;
}

.c-initiative__list-item:before {
  content: "";
  display: inline-block;
  height: .5rem;
  width: .5rem;
  border-radius: 100%;
  vertical-align: 2px;
  margin-right: 1.25rem;
  background: #fbbb0e;
}

.is--yellow .c-initiative__list-item:before {
  background: white;
}

.c-teaser {
  padding: 10rem 0;
}

@media screen and (max-width: 767px) {
  .c-teaser {
    padding: 2rem 0;
  }
}

.c-teaser.is--two {
  padding: 5rem 0 0 0;
}

.c-teaser.is--two__inner {
  flex: 1 1;
}

.c-teaser.is--two .c-teaserbox {
  width: 50%;
}

@media screen and (max-width: 1440px) {
  .c-teaser.is--two .c-teaserbox {
    width: 100%;
  }
}

.c-teaser__inner {
  max-width: 75%;
  margin: 0 auto;
  display: flex;
  flex: 1 1 1;
}

@media screen and (max-width: 1440px) {
  .c-teaser__inner {
    flex: none;
    display: block;
    max-width: 85%;
  }
}

.c-teaser .c-teaserbox {
  background: #f7f5ef;
  padding: 3.75rem;
  width: 33%;
}

@media screen and (max-width: 1440px) {
  .c-teaser .c-teaserbox {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-teaser .c-teaserbox {
    padding: 1.75rem;
  }
}

.c-teaser .c-teaserbox:nth-child(2) {
  margin: 0 2rem;
}

@media screen and (max-width: 1440px) {
  .c-teaser .c-teaserbox:nth-child(2) {
    margin: 2rem 0;
  }
}

.c-teaser .c-teaserbox__headline {
  color: #fbbb0e;
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: 1.25;
}

@media screen and (max-width: 767px) {
  .c-teaser .c-teaserbox__headline {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.c-teaser .c-teaserbox__text {
  font-size: 1.375rem;
  line-height: 1.65;
  margin-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .c-teaser .c-teaserbox__text {
    font-size: 1rem;
  }
}

.c-teaser .c-teaserbox__link {
  display: inline-block;
  background: #fbbb0e;
  color: white;
  padding: 1rem 3rem;
  text-decoration: none;
  transition: .35s ease background;
  border-radius: 3px;
  font-size: 1.125rem;
  font-family: "Muli";
}

@media screen and (max-width: 767px) {
  .c-teaser .c-teaserbox__link {
    padding: 1.25rem;
  }
}

.c-teaser .c-teaserbox__link:hover {
  background: #d39b03;
  transition: .35s ease background;
}

.c-logo {
  position: fixed;
  left: 0;
  top: 2.5rem;
  padding: 1rem;
  background: white;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  z-index: 10000;
}

@media screen and (max-width: 1024px) {
  .c-logo {
    position: fixed;
  }
}

@media screen and (max-width: 767px) {
  .c-logo {
    padding: .5rem;
  }
}

@media screen and (max-width: 767px) {
  .c-logo img {
    width: 50px;
  }
}

.c-menu {
  position: absolute;
  right: 3rem;
  top: 6rem;
  z-index: 999;
}

@media screen and (max-width: 1200px) {
  .c-menu {
    display: none;
  }
}

.c-menu__list {
  text-align: right;
}

.c-menu__list-item {
  display: inline-block;
  margin-left: 2rem;
}

@media screen and (max-width: 1340px) {
  .c-menu__list-item {
    margin-left: 1rem;
  }
}

.c-menu__list-item.is--button {
  padding: 1rem 2rem;
  background: #fbbb0e;
  transition: .35s ease background;
  border-radius: 3px;
}

@media screen and (max-width: 1340px) {
  .c-menu__list-item.is--button {
    padding: 1rem 1rem;
  }
}

.c-menu__list-item.is--button:hover {
  background: #d39b03;
  transition: .35s ease background;
}

.c-menu__list-item.is--button a {
  color: white !important;
}

.c-menu__list-item a {
  font-family: "Rubik", sans-serif;
  font-size: 1.125rem;
  text-decoration: none;
  color: white;
  transition: .35s ease color;
  font-weight: 400;
}

.c-menu__list-item a.is--active {
  color: #fbbb0e !important;
  border-bottom: 3px solid #fbbb0e;
  padding-bottom: 1rem;
}

.is--grey .c-menu__list-item a {
  color: #aaa;
}

.is--black .c-menu__list-item a {
  color: #000000;
}

.c-menu__list-item a:hover {
  color: #fbbb0e;
  transition: .35s ease color;
}

.c-menu__list-item.is--button a.is--active {
  color: white !important;
  border-bottom: 0 !important;
}

.c-nav {
  display: block;
}

@media screen and (min-width: 1201px) {
  .c-nav {
    display: none;
  }
}

.c-nav__toggle {
  position: fixed;
  top: 5rem;
  right: 0rem;
  height: 6rem;
  width: 7rem;
  z-index: 10000;
  background: none;
  border: 0;
  outline: 0;
  background: white;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 767px) {
  .c-nav__toggle {
    height: 4rem;
    width: 5rem;
    top: 2.75rem;
  }
}

.c-nav__toggle-line {
  height: .5rem;
  width: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  background: grey;
}

@media screen and (max-width: 767px) {
  .c-nav__toggle-line {
    height: .35rem;
  }
}

.c-nav__toggle-line:first-child {
  top: 25%;
}

.c-nav__toggle-line:nth-child(2) {
  top: 50%;
  margin-top: -.25rem;
}

@media screen and (max-width: 767px) {
  .c-nav__toggle-line:nth-child(2) {
    margin-top: -.175rem;
  }
}

.c-nav__toggle-line:last-child {
  bottom: 25%;
}

.c-nav__menu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  background: #fbbb0e;
  text-align: center;
  display: none;
}

.c-nav__menu.is--active {
  display: block;
}

.c-nav__list {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.c-nav__list-item a {
  color: white;
  text-decoration: none;
  font-size: 4vw;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .c-nav__list-item a {
    font-size: 8vw;
  }
}

.c-footer {
  width: 100%;
}

.c-footer__main {
  align-items: center;
  display: flex;
  flex: 1 2;
  padding: 5rem 2rem 2rem 2rem;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .c-footer__main {
    display: block;
    flex: none;
  }
}

.c-footer__main .c-contact {
  margin-right: 10rem;
}

@media screen and (max-width: 1800px) {
  .c-footer__main .c-contact {
    margin-right: 7rem;
  }
}

@media screen and (max-width: 1440px) {
  .c-footer__main .c-contact {
    margin-right: 4rem !important;
  }
}

@media screen and (max-width: 767px) {
  .c-footer__main .c-contact {
    margin-bottom: 3rem;
    margin-right: 0;
  }
}

.c-footer__main .c-contact__headline {
  color: #fbbb0e;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.c-footer__main .c-contact__text {
  font-size: 1.375rem;
  line-height: 1.75;
}

@media screen and (max-width: 1440px) {
  .c-footer__main .c-partner {
    margin-right: 0 !important;
  }
}

.c-footer__main .c-partner__headline {
  color: #fbbb0e;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.c-footer__main .c-partner__list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-footer__main .c-partner__list-item {
  display: inline-block;
  margin-right: 3rem;
  opacity: .35;
  transition: 1s ease opacity;
}

@media screen and (max-width: 1024px) {
  .c-footer__main .c-partner__list-item {
    margin: 1rem;
    text-align: center;
  }
  .c-footer__main .c-partner__list-item img {
    width: 125px;
  }
}

.c-footer__main .c-partner__list-item:hover {
  opacity: 1;
  transition: 1s ease opacity;
}

.c-footer__mini {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .c-footer__mini {
    display: block;
    flex: none;
  }
}

.c-footer__mini > div {
  margin-top: 2rem;
}

@media screen and (max-width: 767px) {
  .c-footer__mini > div {
    width: 100%;
  }
}

.c-footer__mini .c-footmenu {
  text-align: right;
}

@media screen and (max-width: 767px) {
  .c-footer__mini .c-footmenu {
    text-align: left;
  }
}

.c-footer__mini .c-footmenu__list-item {
  display: inline-block;
  margin-left: 1rem;
}

@media screen and (max-width: 767px) {
  .c-footer__mini .c-footmenu__list-item {
    margin-left: 0;
    margin-right: .5rem;
  }
}

.c-footer__mini .c-footmenu__list-item a {
  color: #fbbb0e;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .c-footer__mini .c-footmenu__list-item a {
    display: inline-block;
    padding: .25rem;
  }
}

.c-gallery {
  margin-left: 15%;
  margin-right: 15%;
  max-width: 100%;
}

@media screen and (max-width: 1440px) {
  .c-gallery {
    margin: auto;
  }
}

.c-gallery__grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-gallery__grid-cell {
  flex-grow: 1;
  margin: 0.3rem;
  width: 40%;
}

.c-gallery__grid-cell.is--tree {
  width: 25%;
}

.c-gallery__grid-cell img {
  display: block;
}

.c-gallery__grid-cell img.responsive-image {
  max-width: 100%;
}
